<template>
  <section>
    <div class="switch">
      <div class="language-switcher" @click="langChange">
        <input v-if="lang == 'English'" type="checkbox" checked />
        <input v-else type="checkbox" />
        <span class="slider round"></span>
        <span class="select-ne">ने</span>
        <span class="select-en">EN</span>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    langChange() {
          this.$store.dispatch('language');
          this.$emit("changeLang")
    },
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
    }),
  },
};
</script>

<style lang="scss" scoped>
// below are the css rules copied from LoginNavHeader.vue

$height: 24px;
$speed3: cubic-bezier(0.26, 0.48, 0.08, 0.9);
    $deep-blue: #B71C1C;

div.switch {
  cursor: pointer;
  div.language-switcher {
    position: relative;
    display: inline-block;
    width: calc(#{$height} * 2);
    height: $height;
    /* -webkit-transform: translateY(40%);
             -moz-transform: translateY(40%);
             -ms-transform: translateY(40%);
             -o-transform: translateY(40%);
             transform: translateY(40%);*/

    // Closing Animation
    -webkit-transition: transform 0.17s $speed3;
    -moz-transition: transform 0.17s $speed3;
    -ms-transition: transform 0.17s $speed3;
    -o-transition: transform 0.17s $speed3;
    transition: transform 0.17s $speed3;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    span.select-ne,
    span.select-en {
      position: absolute;
      font-size: #{$height / 2.5};
      color: #fff;
      mix-blend-mode: difference;
    }

    span.select-ne {
      top: #{$height / 4};
      left: #{$height / 3};
    }

    span.select-en {
      top: #{$height / 4.5};
      right: #{$height / 4};
    }

    span.slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $deep-blue;
      box-shadow: 0 3px 64px rgba($deep-blue, 0.1);
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    span.slider:before {
      position: absolute;
      content: "";
      height: $height;
      width: $height;
      left: 0;
      bottom: 0;
      background-color: white;
      box-shadow: 0 3px 64px rgba($deep-blue, 0.16);
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: $deep-blue;
    }

    input:focus + .slider {
      box-shadow: none;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX($height);
      -ms-transform: translateX($height);
      transform: translateX($height);
    }

    /* Rounded sliders */
    span.slider.round {
      border-radius: $height;
    }

    span.slider.round:before {
      border-radius: 50%;
    }
  }
}
</style>
