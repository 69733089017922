<template>
    <v-card>
        <v-toolbar fixed dark color="blue" class="darken-1">
            <v-toolbar-title>
                Edit Customer
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.fullName"
                                    label="First Name "
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-radio-group v-model="customerData.gender" row label="Gender">
                                <v-radio label="Male" value="Male"></v-radio>
                                <v-radio label="Female" value="Female"></v-radio>
                                <v-radio label="Other" value="Other"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="snackbar.dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="customerData.dateOfBirth"
                                            label="Date of Birth"
                                            clearable
                                            prepend-icon="event"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.dateOfBirth">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.nationality"
                                    label="Nationality"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.country"
                                    :items="countries"
                                    label="Country"
                                    clearable
                                    single-line
                                    item-text="value"
                                    item-value="id"
                                    @change="getState"
                            ></v-autocomplete>
                        </v-col>

                        <!--<v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.state"
                                    :items="state"
                                    label="State"
                                    clearable
                                    single-line
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>-->


                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.ticketNumber"
                                    label="Ticket Number"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="customerData.customerType" :items="PassengerTypes"
                                            label="Customer Type"></v-autocomplete>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.serviceID"
                                    :items="Services"
                                    chips
                                    deletable-chips
                                    label="Services"
                                    clearable
                                    multiple
                                    item-text="value"
                                    item-value="value"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.packageID"
                                    :items="AirPackages"
                                    chips
                                    deletable-chips
                                    label="Package"
                                    clearable
                                    multiple
                                    item-text="value"
                                    item-value="value"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.citizenShipNumber"
                                    label="Citizenship Number"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.passportNumber"
                                    label="Passport Number"
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.licenseNumber"
                                    label="License Number "
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.otherID"
                                    label="Other Id "
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.document"
                                    label="Document"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-switch
                                    class="col-sm-6 col-md-4"
                                    v-model="customerData.isVerifiedDocument"
                                    label="Is Verified Document?"
                                    clearable
                            ></v-switch>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.issuedBy"
                                    label="Issued By"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="snackbar.dateMenu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="customerData.issuedDate"
                                            label="Issued Date"
                                            clearable
                                            prepend-icon="event"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.issuedDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>

        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="editFormSubmit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "HelicopterCustomerEditForm",
        data() {
            return {
                AirPackages: [],
                customerData: {},
                customerSubmitData: {},
                customerFormData: [],
                message: {
                    status: true,
                    text: 'true',
                },
                snackbar: {
                    dateMenu: false,
                    dateMenu2: false,
                    dateMenu3: false,
                },
                countries: [],
                state: [],
                airList: [],
                Destinations: [],
                Services: [],
                PassengerTypes: ['Adult', 'Child', 'Old'],
                FlightClasses: ['a'],
                Issuer: ['E-Sewa'],
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            }
        },
        watch: {
            "customerData.country": {
                handler: async function (val) {
                    if (val) {
                        const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                        this.state = stateList.data
                    }
                },
                deep: true
            }
        },
        props: ['editItemIndex']
        ,
        computed: {},
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },

            editFormSubmit() {
                this.$delete(this.customerData, 'createdBy')
                this.customerData.IsImportFromExcel = false
                this.customerData.issuedDate = this.customerData.issuedDate == "" ? null : this.customerData.issuedDate
                this.customerSubmitData = this.customerData
                if (this.customerSubmitData.serviceID) {
                    this.customerSubmitData.serviceID = this.customerData.serviceID.join()
                }
                if (this.customerSubmitData.packageID) {
                    this.customerSubmitData.packageID = this.customerData.packageID.join()
                }
                this.customerData.age = parseInt(this.customerData.age)
                axios.post('Adventure/UpdateCustomerInformationAsync', this.customerSubmitData)
                    .then((response) => {
                        if (response.data.success) {
                            this.message.status = true
                            this.servicesData.data = response.data
                            this.servicesData.message = "Customer updated Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    })
                    .catch((response) => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()
                    })
            },
            async getState(val) {
                const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                this.state = stateList.data
            },
            async formData() {
                const countryList = await axios.get("Shared/CountryList");
                this.countries = countryList.data;
                const Services = await axios.get("Adventure/DDLAdventureServiceListAsync");
                this.Services = Services.data

                const Package = await axios.get('Adventure/DDLAdventurePackageListAsync');
                this.AirPackages = Package.data
                const FlightClasses = await axios.get("StaticValues/DDLCommonStaticValuesListAsync/101");
                this.FlightClasses = FlightClasses.data

            },
            async getEditData() {
                const CustomerData = await axios.get('Adventure/GetCustomerInformationByIDAsync/' + this.editItemIndex)
                this.customerData = CustomerData.data

            }
        },
        mounted() {
            this.formData();
            this.getEditData()
        },
    }
</script>

<style scoped>

</style>